body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





@font-face {
    font-family: 'basic_sans';
		src: url('/fonts/basicsans-regular-webfont.eot');
		src: url('/fonts/basicsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
				 url('/fonts/basicsans-regular-webfont.woff2') format('woff2'),
				 url('/fonts/basicsans-regular-webfont.woff') format('woff'),
				 url('/fonts/basicsans-regular-webfont.ttf') format('truetype'),
				 url('/fonts/basicsans-regular-webfont.svg#basic_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


body {
  background-color: white;
  min-height: 100vh;

  font-size: calc(10px + 2vmin);
  color: black;
}

a {
  color: white;
}

.App {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;

	font-family: "basic_sans", "museo-slab", Helvetica, Arial, Sans-Serif;
	font-size: 20px;
}

.Top{

	  background-color: #4F3B70;
	  color: #FFF;
}
.Menu{
	  margin: auto;
	  max-width: 1000px;
}

.Menu img{
	  margin: 1em;
	  max-height: 4em;
}

.centerLeft{
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 85vh;
}

.uploadBox{
    margin-left: auto;
    margin-right: auto;
    max-width: 200px;
    padding: 1em;
    border: 1px dashed grey;
}
.upload{
		text-align: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .centerLeft{
        text-align: center;
    }
    .uploadBox{
        text-align: center;
    }
    .center{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}


.light{
	color: #545454;

}

